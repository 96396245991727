<template>
  <div>
    <b-overlay :show="isLoading">
      <b-container>
        <b-row>
          <b-col cols="6"><b-form-input ref="txtSearch" v-model="searchString" placeholder="Filter Curriculum Objects"></b-form-input></b-col>
          <b-col>
            <b-row>
              <b-col>
                <b-button @click="undoLast" :disabled="undoAvailable">
                  <font-awesome-icon icon="fa-solid fa-rotate-left" />
                </b-button>
                <span style="display: none;">{{undoQueueLength}}</span>
              </b-col>
              <b-col><b-button @click="revertAll">Revert All</b-button></b-col>
              <b-col><b-button variant="danger" @click="clearList">Clear List</b-button></b-col>
              <b-col><img v-if="isSaving" src="/media/loading/yinyang.gif" class="save-spinner"/></b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="source-list">
            <div class="vue-grid-layout source-grid">
              <div class="vue-grid-item" v-for="item in sourceList" :key="item.i"
                draggable="true"
                @drag="drag"
                @dragend="dragend">
                <font-awesome-icon class="document-logo" :icon="parseResourceTypeFA(item.resourceType)" />
                <!-- <img :src="parseResourceType(item.resourceType)" :alt="item.resourceName" /> -->
                <span class="title">Resource Name:</span><span>{{item.resourceName}}</span><br/>
                <span class="title">Language:</span><span>{{item.language ? item.language : 'EN'}}</span>
                <!--
                  This is a required element for the Drag and Drop functionality
                  in order to pass along the appropriate index that was selected
                  from the source list.
                -->
                <input type="hidden" :value="item.i" name="sourceListIndex" />
                <!-- END -->
              </div>
            </div>
          </b-col>
          <b-col>
            <grid-layout class="destination-list"
              id="content"
              style="min-height: 500px;"
              ref="filmstrip"
              :layout.sync="filmStrip"
              :col-num="1"
              :row-height="20"
              :is-dragable="dragable"
              :is-resizable="resizable"
              :vertical-compact="true"
              :use-css-transform="false">
              <grid-item v-for="item in filmStrip" :key="item.i"
                :static="item.static"
                :x="item.x"
                :y="item.y"
                :w="item.w"
                :h="item.h"
                :i="item.i"
                @move="itemMove"
                @moved="itemMoved"
                :class="item.static ? 'static' : ''">
                <font-awesome-icon class="document-logo" :icon="parseResourceTypeFA(item.resourceType)" />
                <!-- <img :src="parseResourceType(item.resourceType)" :alt="item.title" /> -->
                <span class="title">Resource Name:</span><span>{{item.resourceName}}</span><br/>
                <span class="title">Language:</span><span>{{item.language ? item.language : 'EN'}}</span>
                <font-awesome-icon @click="removeItem(item)" class="remove-item" icon="fa-solid fa-trash-can" />
              </grid-item>
            </grid-layout>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </div>
</template>

<script lang="ts">
import { GridLayout, GridItem } from 'vue-grid-layout';
import HierarchySvc from '../services/hierarchy.service';
import AuthorSvc from '../services/authoring.service';

let mouseXY = { x: null, y: null };
let DragPos = { x: null, y: null, w: 2, h: 3, i: null};

export default {
  components: {
    GridLayout,
    GridItem,
  },
  data() {
    return {
      layout: [],
      filmStrip: [],
      initialState: {},
      undoQueue: [],
      moveStarted: false,
      dragable: true,
      resizable: false,
      index: 0,
      searchString: '',
      isLoading: true,
      isSaving: false,
      removeFromSource: true,
      mode: 'PLAY_LIST',
      curriculumGuid: '00FB3C04-5821-418E-ABE8-B11E7275C7D8',
      hierarchyGuid: 'F5909C3B-9D9D-4DA6-BAB5-8937B0F9E916',
      hierarchyCode: '',
      assetId: 2,
      templateId: 1,
    };
  },
  mounted() {
    document.addEventListener('dragover', this.dragover, false);

    // Event Listener for info from WebCon
    window.addEventListener('message', this.receiveDataFromParent, false);

    // Let the parent window know we are loaded
    window.parent.postMessage('IFRAME_LOADED', '*');

    this.$refs.txtSearch.focus();

    // For Debug Purposes
    this.fetchData();
  },
  destroyed() {
    document.removeEventListener('dragover', this.dragover);

    // Remove Event Listender for info from WebCon
    window.removeEventListener('message', this.receiveDataFromParent);
  },
  computed: {
    sourceList() {
      return this.layout.filter(item => {
        return item.resourceName.toLowerCase().includes(this.searchString.toLowerCase()) && !item.isHidden;
      });
    },
    undoQueueLength() {
      return this.undoQueue.length;
    },
    undoLastIndex() {
      return this.undoQueueLength - 1;
    },
    undoAvailable() {
      return this.undoQueueLength === 0;
    }
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      let generatedAssetInputs = [];

      await Promise.all([
        HierarchySvc.getHierarchyObjects(this.curriculumGuid, this.hierarchyGuid)
          .then(response => {
            this.layout = HierarchySvc.mapHierarchyObjectsToUIObjects(response);
          }),
        AuthorSvc.getGeneratedAssetInputs(this.assetId)
          .then(response => {
            generatedAssetInputs = response;
          }),
      ]);

      for (let index = 0; index < generatedAssetInputs.length; index++) {
        const assetInput = generatedAssetInputs[index];
        const layoutItem = this.layout.filter(item => {
          return item.objectId === assetInput.objectId;
        });
        if (layoutItem.length === 1) {
          layoutItem[0].isHidden = true;
          this.filmStrip.push(
            { x:0,y:(index * 3),w:2,h:3,i:index,
              isHidden: false,
              imageUrl: layoutItem[0].iconUrl,
              title: layoutItem[0].resourceName,
              description: '',
              resourceName: layoutItem[0].resourceName,
              resourceType: layoutItem[0].resourceType,
              resourceId: layoutItem[0].resourceId,
              objectId: layoutItem[0].objectId,
              language: layoutItem[0].language,
            });
        }
      }
      this.initialState = {
        layout: JSON.parse(JSON.stringify(this.layout)),
        filmStrip: JSON.parse(JSON.stringify(this.filmStrip)),
      };

      this.isLoading = false;
    },
    receiveDataFromParent(event) {
      if (event.origin !== 'https://dev.bpm.pcgus.com') {
        // console.log(`Expected Origin mismatch. Received ${event.origin}`);
        return;
      }
      // Process the message received

      // Fetch Data for the load
      this.fetchData();
    },
    removeItem(item) {
      this.setUndoState();
      const itemIndex = this.filmStrip.findIndex(ai => { 
        return ai.objectId === item.objectId;
      });
      this.filmStrip.splice(itemIndex, 1);
      const originalItem = this.layout.filter(l => {
        return l.objectId === item.objectId
      });
      if (originalItem.length === 1) {
        originalItem[0].isHidden = false;
      }
      this.saveOrder();
    },
    clearList() {
      this.$bvModal.msgBoxConfirm('Are you sure you want to clear this entire list?', {
        title: 'Confirm Clearing List',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        hideHeaderClose: true,
      }).then(value => {
        if (value) {
          this.filmStrip = [];
          this.layout.forEach(item => {
            item.isHidden = false;
          });
          this.saveOrder();
          this.setUndoState();
        }
      });
    },
    parseResourceType(type) {
      switch (type) {
        case 'GoogleDoc':
          return './media/icons/docs.svg'
        case 'GoogleSlide':
          return './media/icons/slides.svg'
        case 'GoogleSheet':
          return './media/icons/sheets.svg'
        case 'Word':
        case 'WebUrl':
        case 'EmbeddedVideo':
        case 'Video':
        case 'Audio':
        case 'Rtf':
        case 'Image':
          
          break;
      
        default:
          break;
      }
    },
    parseResourceTypeFA(type) {
      switch (type) {
        case 'Word':
        case 'GoogleDoc':
          return 'fa-solid fa-file-lines';
        case 'GoogleSlide':
          return './media/icons/slides.svg'
        case 'GoogleSheet':
          return 'fa-solid fa-table';
        case 'Template':
          return 'fa-solid fa-file-code';
        case 'EmbeddedVideo':
        case 'Video':
          return 'fa-solid fa-file-video';
        case 'Audio':
          return 'fa-solid fa-file-audio';
        case 'Image':
          return 'fa-solid fa-file-image';      
        case 'WebUrl':
          return 'fa-solid fa-file fa-link';
        case 'Rtf':
        default:
          return 'fa-solid fa-file'
      }
    },
    saveOrder() {
      this.isSaving = true;
      const newList = this.filmStrip.sort((a, b) => {
        if (a.y === b.y) {
          return a.x - b.x;
        }
        return a.y - b.y;
      });

      AuthorSvc.saveGeneratedAssetInputs(this.assetId, this.templateId, this.webConUser, newList)
        .then(() => {
          this.isSaving = false;
        });
    },
    setUndoState() {
      this.undoQueue.push({
        layout: JSON.parse(JSON.stringify(this.layout)),
        filmStrip: JSON.parse(JSON.stringify(this.filmStrip)),
      });
      if (this.undoQueue.length > 10) {
        this.undoQueue.shift();
      }
    },
    undoLast() {
      const lastState = this.undoQueue.pop();

      this.layout = JSON.parse(JSON.stringify(lastState.layout));
      this.filmStrip = JSON.parse(JSON.stringify(lastState.filmStrip));
      this.saveOrder();
    },
    revertAll() {
      this.layout = JSON.parse(JSON.stringify(this.initialState.layout));
      this.filmStrip = JSON.parse(JSON.stringify(this.initialState.filmStrip));
      this.undoQueue = [];
      this.saveOrder();
    },
    itemMove() {
      if (this.moveStarted) {
        return;
      }
      this.moveStarted = true;
      this.setUndoState();

    },
    itemMoved() {
      this.saveOrder();
      this.moveStarted = false;
    },
    dragover(e) {
      mouseXY.x = e.clientX;
      mouseXY.y = e.clientY;
    },
    drag() {
        let parentRect = document.getElementById('content').getBoundingClientRect();
        let mouseInGrid = false;
        if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
            mouseInGrid = true;
        }
        if (mouseInGrid === true && (this.filmStrip.findIndex(item => item.i === 'drop')) === -1) {
            this.filmStrip.push({
                x: (this.filmStrip.length * 2) % (this.colNum || 12),
                y: this.filmStrip.length + (this.colNum || 12), // puts it at the bottom
                w: 2,
                h: 2,
                i: 'drop',
            });
        }
        let index = this.filmStrip.findIndex(item => item.i === 'drop');
        if (index !== -1) {
          try {
            this.$refs.filmstrip.$children[this.filmStrip.length].$refs.item.style.display = "none";
          // eslint-disable-next-line no-empty
          } catch {
            // Sometimes, this is not found, and it is ok, not really an error condition
            // this empty try/catch stops console errors, but requires the eslint line above
            // to supress any lint errors.
          }
          let el = this.$refs.filmstrip.$children[index];
          el.dragging = {"top": mouseXY.y - parentRect.top, "left": mouseXY.x - parentRect.left};
          let new_pos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left);
          if (mouseInGrid === true) {
              this.$refs.filmstrip.dragEvent('dragstart', 'drop', new_pos.x, new_pos.y, 1, 1);
              DragPos.i = String(index);
              DragPos.x = this.filmStrip[index].x;
              DragPos.y = this.filmStrip[index].y;
          }
          if (mouseInGrid === false) {
              this.$refs.filmstrip.dragEvent('dragend', 'drop', new_pos.x, new_pos.y, 1, 1);
              this.filmStrip = this.filmStrip.filter(obj => obj.i !== 'drop');
          }
        }
    },
    dragend(e) {
      let parentRect = document.getElementById('content').getBoundingClientRect();
      let mouseInGrid = false;
      if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
          mouseInGrid = true;
      }
      if (mouseInGrid === true) {
        //let targetElement = e.targetElement.$children.filter((t) => { t.});
        this.$refs.filmstrip.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, 1, 1);
        this.filmStrip = this.filmStrip.filter(obj => obj.i !== 'drop');

        // Record Undo here
        this.setUndoState();

        // Requires a hidden named element of 'sourceListIndex' in the HTML for each list item
        const dragListIndex = e.srcElement.children.namedItem('sourceListIndex').value;
        
        // The following line is responsible for hiding the nodes in the source list.
        if (this.removeFromSource) {
          this.layout[dragListIndex].isHidden = true;
        }

        this.filmStrip.push({
            x: DragPos.x,
            y: DragPos.y,
            w: DragPos.w,
            h: DragPos.h,
            i: DragPos.i,
            originalIndex: dragListIndex,
            imageUrl: this.layout[dragListIndex].imageUrl,
            title: this.layout[dragListIndex].title,
            description: this.layout[dragListIndex].description,
            resourceName: this.layout[dragListIndex].resourceName,
            resourceType: this.layout[dragListIndex].resourceType,
            resourceId: this.layout[dragListIndex].resourceId,
            objectId: this.layout[dragListIndex].objectId,
            language: this.layout[dragListIndex].language,
        });

        // Save to API here
        this.saveOrder();

        this.$refs.filmstrip.dragEvent('dragend', DragPos.i, DragPos.x,DragPos.y,1,1);
        try {
            this.$refs.filmstrip.$children[this.filmStrip.length].$refs.item.style.display="block";
        // eslint-disable-next-line no-empty
        } catch {
          // Sometimes, this is not found, and it is ok, not really an error condition
          // this empty try/catch stops console errors, but requires the eslint line above
          // to supress any lint errors.
        }
      }
      this.$refs.txtSearch.focus();
    },
  }
}
</script>

<style scoped>
.btn {
    color: #333;
    background-color: #fdfdfd;
    border: 1px solid #ababab;
    border-radius: 0;
    padding: 6px 12px;
    font-size: 12px;
    text-align: center;
    line-height: 1.42857143;
    transition: all .05s ease-in;
    cursor: pointer;
    width: 100%;
}
.save-spinner {
  height: 35px;
}
.source-list {
  padding-right: 15px;
}
.destination-list {
  padding-left: 15px;
}
.vue-grid-layout {
    background: #eee;
    min-height: 500px;
}
.vue-grid-layout.source-grid {
  padding: 5px;
  height: 500px;
  overflow-y: scroll;
}
.vue-grid-layout.source-grid .vue-grid-item {
  width: 95%;
  height: 50px;
  margin: 5px;
}
.vue-grid-layout .vue-grid-item span.title{
  font-weight: bold;
  padding-left: 3px;
}
.vue-grid-layout.source-grid .vue-grid-item:hover {
  cursor: move;
}
.vue-grid-item {
  border-radius: .5em;
  overflow: hidden;
  text-align: left;
}
.vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
}
.vue-grid-item .resizing {
    opacity: 0.9;
}
.vue-grid-item .static {
    background: #cce;
}
.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}
.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}
.vue-grid-item .minMax {
    font-size: 12px;
}
.vue-grid-item .add {
    cursor: pointer;
}
.vue-grid-item.static {
  border-width: 2px;
}
.vue-grid-layout.source-grid .vue-grid-item img {
  padding: 3px 0;
}
.vue-grid-item img {
  width: 25%;
  height: 100%;
  float: left;
  padding: 5px 0;
}
.vue-grid-layout.source-grid .vue-grid-item .document-logo {
  font-size: 2em;
  margin-top: 3px;
}
.vue-grid-item .document-logo {
    font-size: 4em;
    padding: 5px;
    float: left;
    margin: 2px 15px;
    color: #518FF5;
    background-color: white;
    border-radius: 5px;
}
.vue-grid-item p {
  text-align: left;
  vertical-align: top;
  margin-top: 2px;
}
.vue-grid-item p span {
  font-weight: bold;
  margin-right: 3px;
}
.vue-grid-item .remove-item {
  position: absolute;
  right: 10px;
  bottom: 5px;
  cursor: default;
}
.vue-grid-item .tile-count {
  position: absolute;
  right: 0;
  bottom: 0;
}
.vue-grid-item .tile-count.moved {
  font-weight: bold;
}
.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}
</style>