import axios from 'axios';

const rootUrl = `${process.env.VUE_APP_HIERARCHY_URL}`;
const getUrl = `${rootUrl}/hierarchy`;

export default {
  async getHierarchyObjects(curriculumGuid, hierarchyGuid) {
    const url = `${getUrl}/${curriculumGuid}/${hierarchyGuid}`;
    const resposne = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        }
      }
    );
    return resposne.data;
  },
  mapHierarchyObjectsToUIObjects(hierarchyObjects) {
    const resultList = [];

    for (let index = 0; index < hierarchyObjects.length; index++) {
      resultList.push(
        { x:(index * 2),y:0,w:2,h:2,i:index,
          isHidden: false,
          imageUrl: '',
          title: hierarchyObjects[index].resourceName,
          description: '',
          resourceName: hierarchyObjects[index].resourceName,
          resourceType: hierarchyObjects[index].resourceType,
          resourceId: hierarchyObjects[index].resourceId,
          objectId: hierarchyObjects[index].objectId,
          language: hierarchyObjects[index].language,
        }
      );
      
    }
    return resultList;
  },
}