import axios from 'axios';

const rootUrl = `${process.env.VUE_APP_AUTHOR_URL}`;
const assetInputsUrl = `${rootUrl}/GeneratedAssets/inputs`;

const author_key = `${process.env.VUE_APP_AUTHOR_ID}`;
const author_secret = `${process.env.VUE_APP_AUTHOR_SECRET}`;

let token = '';
//let tokenExpire = '';

export default {
  async doAuth() {
    if (token) {
      return token;
    }
    const url = `${rootUrl}/connect/token`;
    const response = await axios(
      {
        url: url,
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        data: `grant_type=client_credentials&scope=api&client_id=${author_key}&client_secret=${author_secret}`,
      }
    );
    token = response.data.access_token;
    //tokenExpire = response.data.expires_in;
  },
  async getGeneratedAssetInputs(assetId) {
    await this.doAuth();
    const url = `${assetInputsUrl}/${assetId}`;
    const resposne = await axios(
      {
        url: url,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      }
    );
    return resposne.data;
  },
  async saveGeneratedAssetInputs(assetId, templateId, webconUser, assetInputs) {
    const inputModels = [];
    assetInputs.sort((a,b) => a.y - b.y);
    for (let index = 0; index < assetInputs.length; index++) {
      const assetInput = assetInputs[index];
      
      inputModels.push({
        objectId: assetInput.objectId,
        sortOrder: assetInput.i,
        templateId: templateId
      });
    }

    await this.doAuth();

    const url = `${assetInputsUrl}/${assetId}`;
    await axios(
      {
        url: url,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
          'webcon-user': webconUser,
        },
        data: inputModels,
      }
    );

  }
}